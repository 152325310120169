import axios from 'axios'
import {DEV_MODE, API_URL} from '@config'
import {logger} from '@lib/log'
import useSWR from 'swr'

const headers = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
}

const HttpClient = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  withCredentials: true,
  headers,
})

/**
 * Logging request/repsonse interceptors
 */
const log = logger.extend('http:client')

HttpClient.interceptors.request.use(
  (config) => {
    DEV_MODE && log('%s %s%s', config.method.toUpperCase(), config.baseURL, config.url, config.data)
    return config
  },
  (error) => Promise.reject(error)
)

HttpClient.interceptors.response.use(
  (response) => {
    DEV_MODE && log('%s %s', response.status, response.statusText)
    return response
  },
  ({request, response}) => {
    if (!response) {
      log('request failed')
      return Promise.reject({
        message: 'The request was made but no response was received',
        context: {request},
      })
    }
    log('%s %s | %s', response?.status, response?.statusText, response?.data?.message)
    return Promise.reject({context: response, message: response.data?.message})
  }
)

export default HttpClient

export const useGet = <T, TError = any>(key: string) => {
  return useSWR<T, TError>(key, (url) => HttpClient.get(url).then((res) => res.data))
}
