import HttpClient, {useGet} from './httpClient'

export type ApiListResponse = Array<{
  id: string
  token: string

  name: string
  phone: string
  email: string
  school: string
  class: string
  city: string

  deliveryDate: string
  created_at: string
  representant_id: string
  representant_name: string
  service: string
  student_id: string
  size: string
  cover: string
  student_name: string
  student_service: string
  album_size: string
  album_cover: string
}>

export function submitOrder(data) {
  return HttpClient.post('/order.php', data)
}

export function useOrders({secret}) {
  return useGet<ApiListResponse>(`/order.php?secret=${secret}`)
}

export function useOrder({token}) {
  return useGet<ApiListResponse>('order.php?token=' + token)
}
